import React, { Component } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from 'react-redux-form';
import Skill from './SkillComponent';
import DishDetail from './DishdetailComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Home from './HomeComponent';
import Contact from './ContactComponent';
import About from './AboutComponent';
import { postComment, fetchDishes, fetchComments, fetchPromos, fetchLeaders, postFeedback } from '../redux/ActionCreators';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import withRouter from './withRouterWrapper';

const mapStateToProps = state => {
  return {
    dishes: state.dishes,
    comments: state.comments,
    promotions: state.promotions,
    leaders: state.leaders
  }
}

const mapDispatchToProps = dispatch => ({
  postComment: (dishId, rating, author, comment) => dispatch(postComment(dishId, rating, author, comment)),
  postFeedback: (firstname, lastname, telnum, email, contact, agree, contactType, message) => dispatch(postFeedback(firstname, lastname, telnum, email, contact, agree, contactType, message)),
  fetchDishes: () => { dispatch(fetchDishes())},
  resetFeedbackForm: () => { dispatch(actions.reset('feedback'))},
  fetchComments: () => dispatch(fetchComments()),
  fetchPromos: () => dispatch(fetchPromos()),
  fetchLeaders: () => dispatch(fetchLeaders())
});


class Main extends Component {

  componentDidMount() {
    this.props.fetchDishes();
    this.props.fetchComments();
    this.props.fetchPromos();
    this.props.fetchLeaders();
  }

  render() {

    const HomePage = () => {
      return(
          <Home/>
      );
    }

    function DishWithId (match) {
      const id = useParams();
        return(
            <DishDetail id={id}/>
        );
      };

    return (
      <div>
        <Header/>
          <TransitionGroup>
            <CSSTransition key={this.props.router.location.key} classNames="page" timeout={300}>
              <Routes>
                  <Route path='home' element={<HomePage/>} />
                  <Route exact path='skill' element={<Skill/>} />
                  <Route path='/skill/:skillId' element={<DishWithId/>} />
                  <Route exact path='/contactus' element={<Contact/>} />
                  <Route exact path='/aboutus' element={<About/>} />
                  <Route path="*" element={<HomePage/>}/>
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        <Footer/>
      </div>
    );

  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
