import React, { Component } from 'react';
import { Card, CardImgOverlay, CardImg, CardTitle, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FadeTransform, Fade, Stagger } from 'react-animation-components';
import { baseUrl } from '../shared/baseUrl';

class DishDetail extends Component {

  render () {

 function RenderPic ({info}) {
        return(
          <FadeTransform
            in
            transformProps={{
                exitTransform: 'scale(0.5) translateY(-50%)'
            }}>
          <Card>
            <CardImg width="100%" top src={baseUrl + info[0]} alt={info[1]} />
            <CardImgOverlay>
                <CardTitle>{info[1]}</CardTitle>
            </CardImgOverlay>
          </Card>
          </FadeTransform>
        );
}

  function RenderComments({comments}) {
            return (
                <Fade in>
                <div key={comments[3]}>
                    <li>{comments[0]}</li>
                </div>
                </Fade>
            );

  }

                  console.log(this.props.id.skillId)
        let pic = [];
        let comments = [];

if (this.props.id.skillId == 0){
      pic= ["assets/images/React-icon.svg", "React web development"];
      comments = ["I can make basic websites such as this one. I learnt this skill on Cousera and have a certificate from Hong Kong university for my studies.", "React web development"];
      }

else if (this.props.id.skillId == 1) {
      pic = ["assets/images/Moodle-logo.svg", "Moodle"];
      comments = ["During the pandemic encouraged me to use my knowledge to make my Moodle based website. I found my self-teaching online and made extensive use of Moodle both from the educators’ perspective and from an administrator’s perspective. I made, taught from, and upgraded my own Moodle install from Moodle 3.9 to 3.10 and 3.11. These upgrades were done over the weekend and the users did not report any problems the following week. I made extensive use of the H5P integration and during maths made quizzes that give each student their own set of questions. I have also modified the PHP code to make minor changes in behaviour to the site. This included modifying the glossary auto-linking filter so all text would look consistent with the rest of the page whilst offering touch to translate functionality for Thai ESL learners.", "Moodle"];
      }

else if (this.props.id.skillId == 2) {
      pic = ["assets/images/TESOL.jpg", "TESOL"];
      comments = ["I have taught English as a foreign language for over 5 years and have a masters degree in TESOL.", "TESOL"];
      }

else {
      pic = ["assets/images/electronics.jpg", "Electronics"];
      comments = ["I have worked as an electronics test engineer and ships electrician. I also have a degree in electronics system design.", "Electronics"];
      }

        return(

          <div className="container">
                   <div className="row">
                       <Breadcrumb>
                           <BreadcrumbItem><Link to="/skill">Skills</Link></BreadcrumbItem>
                           <BreadcrumbItem active>{comments[1]}</BreadcrumbItem>
                       </Breadcrumb>
                       <div className="col-12">
                           <h3>{comments[1]}</h3>
                           <hr />
                       </div>
                   </div>
                   <div className="row">
                       <div className="col-12 col-md-5 m-1">
                          <RenderPic info={pic} />
                       </div>
                       <div className="col-12 col-md-5 m-1">
                          <h2>Details</h2>
                          <RenderComments comments={comments}/>
                       </div>
                  </div>
            </div>
        );
  }

}
export default DishDetail;
