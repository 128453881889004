import React from 'react';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';


function About(props) {
    return(
        <div className="container">
            <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>About Me</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    <h3>About Me</h3>
                    <hr />
                </div>
            </div>
            <div className="row row-content">
                <div className="col-12 col-md-6">
                    <h2>My History</h2>
                    <p>I have worked extensively in engineering and technical sectors including in electronics manufacturing as a test engineer. I then got my degree in electronics system design then worked on merchant ships as an electrician. During my time on ship, I worked as part of the engineering team to answer to electrical/electronic/IT problems.</p>
                    <p>More recently I have been working as a teacher of English as a foreign language. This has allowed me to bring my technical knowledge into education. During the school holidays I also completed my crash course on python, Front-end web development with React, Moodle teaching basics and Moodle admin basics.</p>
                </div>
                <div className="col-12 col-md-5">
                    <Card>
                        <CardHeader className="bg-primary text-white">Facts At a Glance</CardHeader>
                        <CardBody>
                            <dl className="row p-1">
                                <dt className="col-6">Skills</dt>
                                <dd className="col-6">Basic web design, Moodle, Instructional design, Language learning</dd>
                                <dt className="col-6">Education</dt>
                                <dd className="col-6">BEng in electronics system design, MSc in TESOL</dd>
                                <dt className="col-6">Experience</dt>
                                <dd className="col-6">Electronics testing, Language teaching, website administration</dd>
                            </dl>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default About;
