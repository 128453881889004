import React from 'react';
import { Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle} from 'reactstrap';
import { FadeTransform } from 'react-animation-components';

function RenderCard({image, name, designation, description}) {
    return(
        <FadeTransform
          in
          transformProps={{
              exitTransform: 'scale(0.5) translateY(-50%)'
          }}>
          <Card>
              <CardImg src={image} alt={name} />
              <CardBody>
                <CardTitle><h2>{name}</h2></CardTitle>
                <CardSubtitle><h4>{designation}</h4></CardSubtitle>
                <CardText>{description}</CardText>
              </CardBody>
          </Card>
        </FadeTransform>
      );

}

function Home(props) {
    return(
        <div className="container">
            <div className="row align-items-start">
                <div className="col-12 col-md m-1">
                    <RenderCard image="assets/images/Paul2.jpg" name="Paul Winstanley" designation="Designer of this site" description= "My interests include website design, Moodle, electronics, language Acquisition. My hobbies include walking and cycling." />
                </div>
                <div className="col-12 col-md m-1">
                    <RenderCard image="assets/images/React-icon.svg" name="Web development" designation="React" description= "I can make websites such as this one." />
                </div>
                <div className="col-12 col-md m-1">
                    <RenderCard image="assets/images/Moodle-logo.svg" name="Online materials design" designation="Moodle" description= "I can install, maintain and modify and teach from Moodle according to customer requirements." />
                </div>
            </div>
        </div>
    );
}

export default Home;
