import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return(
    <div className="footer">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-4 offset-1 col-sm-2">
                    <h5>Links</h5>
                    <ul className="list-unstyled">
                      <li><Link to='/home'>Home</Link></li>
                      <li><Link to='/aboutus'>About Me</Link></li>
                      <li><Link to='/skill'>Skills</Link></li>
                      <li><Link to='/contactus'>Contact Us</Link></li>
                    </ul>
                </div>
                <div className="col-7 col-sm-5">
                    <h5>Contact</h5>
		              <i className="fa fa-envelope fa-lg"></i>: <a href="mailto:website@poorly.xyz">
                         website@poorly.xyz</a>
                </div>
                <div className="col-12 col-sm-4 align-self-center">
                    <div className="text-center">
                        <a className="btn btn-social-icon btn-linkedin" href="https://www.linkedin.com/in/paul-m-winstanley/"><i className="fa fa-linkedin"></i></a>
                        <a className="btn btn-social-icon" href="mailto:website@poorly.xyz"><i className="fa fa-envelope-o"></i></a>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-auto">
                    <p>© Copyright 2023 Poorly enterprises</p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;
