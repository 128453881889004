import React from 'react';
import { Card, CardImg, CardImgOverlay, CardTitle, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { baseUrl } from '../shared/baseUrl';


const Skill = (props) => {

            return (
                <div className="container">
                    <div className="row">
                        <Breadcrumb>
                            <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                            <BreadcrumbItem active>Skills</BreadcrumbItem>
                        </Breadcrumb>
                        <div className="col-12">
                            <h3>Skills</h3>
                            <hr />
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-5">
                      <Card>
                          <Link to={`/skill/0`} >
                              <CardImg width="100%" src={baseUrl + "assets/images/React-icon.svg"} alt={"React web development"} />
                              <CardImgOverlay>
                                  <CardTitle>React web development</CardTitle>
                              </CardImgOverlay>
                          </Link>
                      </Card>
                      </div>

                        <div className="col-12 col-md-5">
                      <Card>
                          <Link to={`/skill/1`} >
                              <CardImg width="100%" src={baseUrl + "assets/images/Moodle-logo.svg"} alt={"Moodle"} />
                              <CardImgOverlay>
                                  <CardTitle>Moodle</CardTitle>
                              </CardImgOverlay>
                          </Link>
                      </Card>
                      </div>

                        <div className="col-12 col-md-5">
                      <Card>
                          <Link to={`/skill/2`} >
                              <CardImg width="100%" src={baseUrl + "assets/images/TESOL.jpg"} alt={"TESOL"} />
                              <CardImgOverlay>
                                  <CardTitle>TESOL</CardTitle>
                              </CardImgOverlay>
                          </Link>
                      </Card>
                      </div>

                        <div className="col-12 col-md-5">
                      <Card>
                          <Link to={`/skill/3`} >
                              <CardImg width="100%" src={baseUrl + "assets/images/electronics.jpg"} alt={"Electronics"} />
                              <CardImgOverlay>
                                  <CardTitle>Electronics</CardTitle>
                              </CardImgOverlay>
                          </Link>
                      </Card>
                      </div>
                    </div>
                </div>
            );
//        }
    }

export default Skill;
