import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

class Contact extends Component {

  constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
      console.log('Current State is: ' + JSON.stringify(values));
      alert('Current State is: ' + JSON.stringify(values));
      this.props.postFeedback(values.firstname, values.lastname, values.telnum, values.email, values.contact, values.agree, values.contactType, values.message);
      this.props.resetFeedbackForm();
      // event.preventDefault();
  }


  render() {

    return(
        <div className="container">
            <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Contact Me</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    <h3>Contact Me</h3>
                    <hr />
                </div>
            </div>
            <div className="row row-content">

                  <div className="col-7 col-sm-5">
                      <h5>Contact</h5>
                    <i className="fa fa-envelope fa-lg"></i>: <a href="mailto:website@poorly.xyz">
                           website@poorly.xyz</a>
                  </div>

            </div>

        </div>

    );
  }
}
export default Contact;
